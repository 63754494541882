import React from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle, Button, Typography} from '@mui/material';

const ReCallDialog = ({ open, recall_entry, onClose}) => {
    const RelationalLogsHeading = ({heading, bodyText}) => (
        <Typography sx={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }} key={heading}>
            <Typography sx={{ fontWeight: 'bold', marginRight: '8px' }}>{heading}:</Typography>
            <Typography>{bodyText}</Typography>
        </Typography>
    );

    return (
        <Dialog open={open} onClose={onClose} maxWidth="lg" sx={{ padding: '16px' }}>
            <DialogTitle sx={{ fontWeight: 'bold', fontSize: '1.5rem' }}>Recall Details</DialogTitle>
            <DialogContent>
                <RelationalLogsHeading heading="EUID" bodyText={recall_entry.euid} />
                <RelationalLogsHeading heading="Subject" bodyText={recall_entry.meta_data.subject} />
                <RelationalLogsHeading heading="From Email" bodyText={recall_entry.from_email_id} />
                <RelationalLogsHeading heading="Recipients" bodyText={recall_entry.meta_data.to_email_ids.join(', ')} />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ReCallDialog;
