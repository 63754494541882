import axios from 'axios';


const API_RECALL_BASE_URL = 'https://admin.api.yukthi.net/recall';


const fetchReCallEntries = async (page, pageSize, filters) => {
    const url = `${API_RECALL_BASE_URL}/entries`;
    let params = {current_page: page, page_size: pageSize};

    // Add filters to the API call if they are provided (since its an optional parameter)
    if (filters.from_email_filter_id) {
        params.from_email_filter_id = filters.from_email_filter_id;
    }

    try {
        const response = await axios.get(url, {
            "headers": {
                'accept': 'application/json',
                'Session-ID': localStorage.getItem('Session-ID')
            },
            "params": params
        });
        return response.data;
    } catch (error) {
        console.error('Fetch ReCall Entries API call failed:', error.response.data);
        return error.response.data;
    }
};


const deleteReCallEntry = async (euid) => {
    const url = `${API_RECALL_BASE_URL}/entries/${euid}`;

    try {
        const response = await axios.delete(url, {
            "headers": {
                'accept': 'application/json',
                'Session-ID': localStorage.getItem('Session-ID')
            }
        });
        return response.data;
    } catch (error) {
        console.error('Delete ReCall Entry API call failed:', error.response.data);
        return error.response.data;
    }
};


export { fetchReCallEntries, deleteReCallEntry };
