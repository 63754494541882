import React, { useState, useEffect } from 'react';
import {Box, Typography, TableContainer, Paper, LinearProgress} from '@mui/material';
import LogTable from '../components/logging/LogTable';
import LogDialog from '../components/logging/LogDialog';
import SearchForm from '../components/logging/SearchForm';
import { fetchEmailLogs } from '../api/MailFlowLogs';


function getTimeWithOffset(offset) {
    const date = new Date();
    const tzOffset = date.getTimezoneOffset() * 60000; // Local timezone offset in milliseconds
    const adjustedDate = new Date(date.getTime() - tzOffset + offset * 60000); // Adjust with the given offset in minutes
    return adjustedDate.toISOString().slice(0, 19);
}

const MailFlowLogs = () => {
    const [logs, setLogs] = useState([]);
    const [isLoading, setLoading] = useState(true)
    const [pagination, setPagination] = useState({ total_count: 0, page: 1, per_page: 25, total_pages: 1});
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedLog, setSelectedLog] = useState(null);
    const [filtersData, setFiltersData] = useState({ date_range:{ from_date: getTimeWithOffset(-30), to_date: getTimeWithOffset(0)}});
    const [sortOrder, setSortOrder] = useState({ field: 'timestamp', order: 'desc' });

    useEffect(() => {
        fetchLogs();
    }, [pagination.page, filtersData]);

    const fetchLogs = async () => {
        setLoading(true)
        const response = await fetchEmailLogs(pagination.page, pagination.per_page, filtersData);
        setLogs(response.data);
        setPagination(response.pagination);
        setLoading(false)
    };

    const handleRowClick = (log) => {
        setSelectedLog(log);
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
        setSelectedLog(null);
    };

    const handlePageChange = (event, newPage) => {
        setPagination((prev) => ({ ...prev, page: newPage }));
    };

    const handleSortChange = (field) => {
        const isAsc = sortOrder.field === field && sortOrder.order === 'asc';
        const newOrder = isAsc ? 'desc' : 'asc';
    
        setSortOrder({ field, order: newOrder });
    
        // Sort the logs locally without fetching new data
        const sortedLogs = [...logs].sort((a, b) => {
            if (a[field] < b[field]) {
                return newOrder === 'asc' ? -1 : 1;
            }
            if (a[field] > b[field]) {
                return newOrder === 'asc' ? 1 : -1;
            }
            return 0;
        });
        setLogs(sortedLogs);
    };
    

    const handleSearchChange = (searchData) => {
        setFiltersData(searchData);
        setPagination((prev) => ({ ...prev, page: 1 })); // Reset to the first page
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center' }}>
            <Typography variant="h4" gutterBottom>Mail Flow Logs</Typography>
            <SearchForm onSearchChange={handleSearchChange} />
            {isLoading ? 
                <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                </Box>
            :
                <TableContainer component={Paper}>
                    <LogTable
                        logs={logs}
                        pagination={pagination}
                        onRowClick={handleRowClick}
                        onPageChange={handlePageChange}
                        onSortChange={handleSortChange}
                        sortOrder={sortOrder}
                        isLoading={isLoading}
                    />
                </TableContainer>
            }
            {selectedLog && (
                <LogDialog
                    open={dialogOpen}
                    log={selectedLog}
                    onClose={handleDialogClose}
                    sortOrder={sortOrder}
                />
            )}
            
        </Box>
    );
};

export default MailFlowLogs;
