import React, { useState, useEffect } from 'react';
import {Box, Typography, TableContainer, Paper} from '@mui/material';
import ReCallTable from '../components/recall/ReCallTable';
import ReCallDialog from '../components/recall/ReCallDialog';
import SearchForm from '../components/recall/SearchForm';
import { fetchReCallEntries, deleteReCallEntry } from '../api/MailReCall';


const MailReCall = () => {
    const [recall_data, setReCallData] = useState([]);
    const [pagination, setPagination] = useState({ total_count: 0, page: 1, per_page: 25, total_pages: 1 });
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedEntry, setSelectedEntry] = useState(null);
    const [filtersData, setFiltersData] = useState({});
    const [sortOrder, setSortOrder] = useState({ field: 'timestamp', order: 'desc' });

    useEffect(() => {
        fetchEntries();
    }, [pagination.page, filtersData]);

    const fetchEntries = async () => {
        const response = await fetchReCallEntries(pagination.page, pagination.per_page, filtersData);
        // If the response has no data or pagination, then return nothing
        if (!response.data || !response.pagination) { return; }
        setReCallData(response.data);
        setPagination(response.pagination);
    };

    const handleRowClick = (data) => {
        if (typeof data === 'string') {
            deleteReCallEntry(data);
            window.location.reload();
        } else {
            setSelectedEntry(data);
            setDialogOpen(true);
        }
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
        setSelectedEntry(null);
    };

    const handlePageChange = (event, newPage) => {
        setPagination((prev) => ({ ...prev, page: newPage }));
    };

    const handleSortChange = (field) => {
        const isAsc = sortOrder.field === field && sortOrder.order === 'asc';
        const newOrder = isAsc ? 'desc' : 'asc';
    
        setSortOrder({ field, order: newOrder });
    
        // Sort the recall_data locally without fetching new data
        const sortedEntries = [...recall_data].sort((a, b) => {
            if (a[field] < b[field]) {
                return newOrder === 'asc' ? -1 : 1;
            }
            if (a[field] > b[field]) {
                return newOrder === 'asc' ? 1 : -1;
            }
            return 0;
        });
        setReCallData(sortedEntries);
    };
    

    const handleSearchChange = (fromEmailIDFilter) => {
        setFiltersData(fromEmailIDFilter);
        setPagination((prev) => ({ ...prev, page: 1 })); // Reset to the first page
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center' }}>
            <Typography variant="h4" gutterBottom>Mail ReCall</Typography>
            <SearchForm onSearchChange={handleSearchChange} />
            {recall_data.length === 0 ? (
                <Typography variant="h4">No ReCall data found</Typography>
                ) :
                <TableContainer component={Paper}>
                    <ReCallTable
                        recall_data={recall_data}
                        pagination={pagination}
                        onRowClick={handleRowClick}
                        onPageChange={handlePageChange}
                        onSortChange={handleSortChange}
                        sortOrder={sortOrder}
                    />
                </TableContainer>
            }
            {selectedEntry && (
                <ReCallDialog
                    open={dialogOpen}
                    recall_entry={selectedEntry}
                    onClose={handleDialogClose}
                />
            )}
        </Box>
    );
};

export default MailReCall;
