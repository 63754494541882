import React, { useState } from 'react';
import { Box, TextField, Button, Grid, Typography } from '@mui/material';

const SearchForm = ({ onSearchChange }) => {
    const [from_email_filter_id, setFromEmailFilterId] = useState('');

    const handleSearchChange = () => {
        console.log('Search Data:', from_email_filter_id);
        onSearchChange({ from_email_filter_id });
    };

    return (
        <Grid container justifyContent="center" alignItems="center">
            <Box
                sx={{
                    width: '90%',
                    maxWidth: 600,
                    padding: 3,
                    border: '1px solid #ccc',
                    borderRadius: 2,
                    boxShadow: 3,
                }}
            >
                <Typography variant="h6" gutterBottom>
                    Search mail for ReCall
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            label="From Email ID Filter"
                            variant="outlined"
                            type="email"
                            fullWidth
                            value={from_email_filter_id}
                            onChange={(e) => setFromEmailFilterId(e.target.value)}
                            sx={{ marginBottom: 2 }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="contained" color="primary" fullWidth onClick={handleSearchChange}>
                            Search
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Grid>
    );
};

export default SearchForm;
